import React, { FunctionComponent, useState } from "react";
import { navigate } from "gatsby";
import { Button } from "@components/Button";
import BackgroundMusic from "@components/BackgroundMusic";
import { iconCreate, iconMoreInfo } from "@assets/icons";
import { useClasses } from "@hooks";
import "@styles/NavSide.scss";

export enum NavLink {
  About = "about",
  Create = "create",
}

interface NavSideProps {
  currentlyActive: NavLink;
}

interface NavItemProps {
  icon: string;
  linkTo: NavLink;
  isActive: boolean;
  shouldDisplay: boolean;
  tooltipMessage: string;
}

const NavItem: FunctionComponent<NavItemProps> = ({
  icon,
  linkTo,
  isActive,
  tooltipMessage,
}) => {
  const [isTooltipVisible, setTooltipVisibilty] = useState(false);

  return (
    <li
      data-is-active={isActive}
      onClick={() => navigate(`/${linkTo}`)}
      onMouseEnter={() => setTooltipVisibilty(true)}
      onMouseLeave={() => setTooltipVisibilty(false)}
    >
      <img src={icon} alt={`Link to ${linkTo} page icon`} />
      <Button
        className={useClasses([
          "navTooltip",
          isTooltipVisible ? "visible" : "invisible",
        ])}
      >
        {tooltipMessage}
      </Button>
    </li>
  );
};

export const NavSide = ({ currentlyActive, shouldDisplay = true }) => {
  const navLinks = [
    {
      type: NavLink.Create,
      icon: iconCreate,
      tooltipMessage: "CREATE YOUR LANTERN",
    },
    {
      type: NavLink.About,
      icon: iconMoreInfo,
      tooltipMessage: "LEARN MORE",
    },
  ];

  const navLinksEls = navLinks.map((navLink, idx) => (
    <NavItem
      key={idx}
      icon={navLink.icon}
      linkTo={navLink.type}
      isActive={navLink.type === currentlyActive}
      tooltipMessage={navLink.tooltipMessage}
    />
  ));

  if (!shouldDisplay) return null;

  return (
    <nav>
      <ol>{navLinksEls}</ol>
    </nav>
  );
};
