import React, { FunctionComponent } from "react";
import { Link } from "gatsby";
import "@styles/Button.scss";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  width?: string;
  height?: string;
  color?: string;
  background?: string;
  borderColor: string;
  iconAfter?: string;
  iconBefore?: string;
  href?: string;
  isSimpleAnchor?: boolean;
}

export const Button: FunctionComponent<ButtonProps> = ({
  width,
  height,
  background = "none",
  color = "white",
  borderColor = "white",
  iconAfter,
  iconBefore,
  href,
  isSimpleAnchor,
  children,
  style,
  ...otherProps
}) => {
  const ButtonContentWrap = ({ children }) => (
    <div className="buttonContentWrap">{children}</div>
  );

  const ButtonContent = ({ children }) => (
    <>
      {!!iconBefore && <img src={iconBefore} />}
      {children}
      {!!iconAfter && <img src={iconAfter} />}
    </>
  );

  const hasIcon = !!iconBefore || !!iconAfter;

  const ButtonTag =
    typeof href === "string" && href.length
      ? Link
      : isSimpleAnchor
      ? "a"
      : "button";

  return (
    <ButtonTag
      to={!!href ? href : null}
      style={{
        width,
        height,
        background,
        color,
        ...style,
      }}
      {...otherProps}
    >
      {hasIcon ? (
        <ButtonContentWrap>
          <ButtonContent>{children}</ButtonContent>
        </ButtonContentWrap>
      ) : (
        <ButtonContent>{children}</ButtonContent>
      )}
    </ButtonTag>
  );
};
