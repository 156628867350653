import { useState, useEffect } from "react";

export const useClasses = (classNames) => {
  if (Array.isArray(classNames) && classNames.length) {
    return classNames.reduce(
      (acc, val, idx) =>
        (acc += `${idx === 0 || !val ? "" : " "}${!!val ? val : ""}`),
      ""
    );
  }
};

export const useWindowSize = function (): [number, number] {
  const [windowWidth, setWidth] = useState(0);
  const [windowHeight, setHeight] = useState(0);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return [windowWidth, windowHeight];
};
